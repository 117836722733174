// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
//@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
// @import '../vendors/owl-carousel2/owl.carousel';
// @import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}


/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';
//@import 'sections/section-temoignages';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
//@import 'modules/module-slideshow';
@import 'modules/module-slider-light';
@import 'modules/module-header';
@import 'modules/module-soumission';


a.btn-yellow {
    background: $color-1;
    width: 205px;
    height: 60px;
    transition: 0.3s;
    h5 {
        font-weight: bold;
        font-size: $font-size-16;
        color: $color-black;
        text-transform: uppercase;
    }
    .icon {
        opacity: 0;
        transition: 0.3s;
    }
    &:hover {
        width: 210px;
        transition: 0.3s;
        .icon {
            opacity: 1;
            transition: 0.3s;
        }
    }
    @media screen and (max-width: $size-xs-max) {
        .text.pr2 {
            padding: 0;
        }
        .icon {
            display: none;
        }
    }
}


/*******************************************************************************
* ACCUEIL
*******************************************************************************/

.section_video {
    &__wrapper {
        position: relative;
        video {
            width: 100%;
            height: auto;
        }
        &_overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 3px;
            background:rgba(0, 0, 0, .35);//hsla(9, 100%, 64%, 0.4);//
            @media screen and (max-width: 1024px){
                bottom: 152px;
                // @media screen and (max-width: $size-sm-max) {
                //     bottom: 26.5%;
                //     @media screen and (max-width: $size-xs-max) {
                //         bottom: 17.5%;
                //         @media screen and (max-width: 375px) {
                //             bottom: 19%;
                //             @media screen and (max-width: 360px) {
                //                 bottom: 19.5%;
                //             }
                //         }
                //     }
                // }
            }
            &.services {
                @media screen and (max-width: 1024px){
                    bottom: 3px;
                }
            }
        }
        &_caption {
            position: absolute;
            top: 35%;
            left: 5%;
            right: 5%;
            h3, h2, p {
                text-transform: initial;
                color: $color-white;
            }
            h3 {
                font-weight: normal;
                font-size: $font-size-34;
            }
            h2 {
                font-weight: 600;
                font-size: $font-size-80;
                padding: 5px 0;
            }
            p {
                font-weight: normal;
                font-size: $font-size-18;
                line-height: 1.5;
                margin: 0;
                width: 37%;
            }
            div.main {
                flex: 0 0 auto;
                margin-right: auto;
            }  
            @media screen and (max-width: 1600px) {
                h2 {
                    font-size: 3.5rem;
                }
                p {
                    width: 44%;
                }
                @media screen and (max-width: 1400px) {
                    h3 {
                        font-size: 1.5rem;
                    }
                    h2 {
                        font-size: 3rem;
                    }
                    p {
                        width: 51%;
                    }
                    @media screen and (max-width: 1024px) {
                        p {
                            width: 63%;
                        }
                        @media screen and (max-width: $size-sm-max) {
                            top: 45%;
                            p {
                                width: 100%;
                            }
                            @media screen and (max-width: $size-xs-max) {
                                top: 25%;
                            }
                        }
                    }
                } 
            }
            &.services {
                top: 55%;
                h2 {
                    font-weight: bold;
                    font-size: $font-size-70;
                    color: $color-1;
                }
                @media screen and (max-width: 1500px) {
                    h2 {
                        font-size: 3rem;
                    }
                    @media screen and (max-width: 1024px){
                        h2 {
                            font-size: 2.8rem;
                        }
                        @media screen and (max-width: $size-sm-max) {
                            top: 53%;
                            h2 {
                                font-size: 2.6rem;
                            }
                            @media screen and (max-width: $size-xs-max) {
                                top: 62%;
                                h2 {
                                    br {
                                        display: none;
                                    }
                                }
                                @media screen and (max-width: 360px) {
                                    top: 60%;
                                    h2 {
                                        font-size: 2.45rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &_caption2 {
            position: absolute;
            bottom: 3px;
            left: 0;
            width: 40%;
            height: 155px;
            margin-right: 5%;
            padding: 0 5%;
            background: $color-1;
            .icon {
                padding-right: 60px;
            }
            .text {
                border-left: 2px solid $color-black;
                padding-left: 15px;
                div.phonenumber {
                    font-weight: bold;
                    font-size: $font-size-36;
                    color: $color-black;
                    
                }
            }
            @media screen and (max-width: 1105px){
                width: 45%;
                @media screen and (max-width: 1024px){
                    position: initial;
                    width: 100%;
                    margin-top: -3px;
                }
            }
        }
    }
}

#section-text-image {
    padding: 35px 0 35px 5%;
    .text-side {
        width: 30%;
        margin-right: 50px;
        h4 {
            font-weight: bold;
            font-size: $font-size-34;
            color: $color-black;
            padding-bottom: 25px;
        }
    }
    @media screen and (max-width: 1610px) {
        .text-side h4 {
            font-size: 1.6rem;
            br {  
                display: none;
            }
        }
        @media screen and (max-width: 1450px) {
            .text-side h4 {
                font-size: 1.45rem;
            }
            @media screen and (max-width: 1200px) {
                flex-direction: column;
                padding: 75px 5%;
                .text-side {
                    padding-top: 0;
                    margin: 0 0 35px 0;
                    width: 100%;
                    h4 {
                        font-size: 1.6rem;
                    }
                }
            } 
        }
    }
}

#section-image-form {
    background: #A6ADB441;
    padding: 50px 5% 50px 0;
    .image-side {
        background: url("../images/accueil_section02_img01.jpg") no-repeat;
        background-size: cover;
        margin-right: 25px;
    }
    .section-form {
        background: #fff;
        width: 52%;
        border-top-right-radius: 30px;
        padding: 30px 50px 50px 30px;
        h3 {
            font-weight: bold;
            font-size: $font-size-30;
            color: $color-black;
        }
        .title {
            background: $color-1;
            margin-top: 10px;
            margin-bottom: 20px;
            padding: 10px 20px;
            h4 {
                font-weight: normal;
                font-size: $font-size-18;
                color: $color-black;
                text-transform: uppercase;
            }
        }
    }
    @media screen and (max-width: 1350px) {
        padding: 50px 5%;
        flex-direction: column;
        .image-side {
            width: 60%;
            margin: 0 auto 25px auto;
        }
        .section-form {
            width: 60%;
            margin: auto;
        }
        @media screen and (max-width: 1024px) {
            .image-side, .section-form {
                width: 70%;
            }
            @media screen and (max-width: $size-sm-max) {
                .image-side, .section-form {
                    width: 100%;
                }
                @media screen and (max-width: $size-sm-max) {
                    .image-side {
                        background: url("../images/accueil_section02_img01_m.jpg") no-repeat;
                        background-size: cover;
                    }
                    .section-form {
                        padding: 30px 35px 50px 20px;
                        .pl0, .pr0 {
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* NOS CONTENEURS
*******************************************************************************/

#section-title-page {
    background: $color-1;
    padding: 50px 20px 35px 20px;
    width: fit-content;
    margin: -55px 5% 50px 5%;
    position: relative;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    h2 {
        font-weight: bold;
        font-size: $font-size-48;
        color: $color-black;
    }
    @media screen and (max-width: $size-md-max) {
        width: fit-content;
        @media screen and (max-width: 1200px) {
            margin: -55px 5% 25px 5%;
            @media screen and (max-width: $size-sm-max) {
                margin: -55px 5% 0px 5%;
                width: fit-content;
                @media screen and (max-width: $size-xs-max) {
                    margin: 0;
                    width: 100%;
                    h2 {
                        font-size: 2.2rem;
                        text-align: center;
                    }
                }
            }
        }
    }
}

#section-sub-title-page{
    display: flex;
    justify-content: center;
    padding: 40px 5% 40px 5%;
    h2{
        font-size: $font-size-48;
        font-weight: bold;
        text-align: center;
    }
}

#section-title-image {
    .section-title {
        padding: 40px 5% 75px 5%;
        .title {
            margin-right: 13%;
            h2 {
                font-weight: bold;
                font-size: $font-size-60;
                color: $color-black;
                text-transform: uppercase;
            }
        }
        .border {
            background: #2F2F2F;
            height: 3px;
            width: 32%;
        }
    }
    @media screen and (max-width: 1765px) {
        .section-title {
            .title {
                h2 {
                    font-size: 2.6rem;
                }  
            }
        } 
        @media screen and (max-width: $size-sm-max) {
            .section-title {
                .title {
                    h2 {
                        font-size: 2.3rem;
                    }  
                }
            } 
            @media screen and (max-width: $size-xs-max) {
                .section-title {
                    flex-direction: column;
                    .title {
                        margin: 0 0 25px 0;
                    }
                    .border {
                        width: 98%;
                    }
                } 
            }
        }
    }
}

#section-bloc-image {
    padding: 35px 5% 0 5%;
    .left-side {
        width: 57%;
        background: #F2F2F2;
        margin-right: 15px;
        padding: 75px 50px;
        h3 {
            font-weight: bold;
            font-size: $font-size-30;
            text-transform: initial;
            color: $color-black;
            padding-bottom: 35px;
        }
        p {
            padding-bottom: 25px;
        }
        a.btn-yellow {
            width: 210px;
            &:hover {
                width: 215px;
            }
            &.small {
                width: 175px;
                &:hover {
                    width: 180px;
                }
            }
            &.long {
                width: 275px;
                &:hover {
                    width: 280px;
                }
            }
        }
    }
    .right-side {
        &.pic01 {
            width: 43%;
            background: url(../images/conteneurs_section03_img01.jpg) no-repeat;
            background-size: cover;
            background-position: center;
            @media screen and (max-width: $size-xs-max) {
                background: url(../images/conteneurs_section03_img01_m.jpg) no-repeat;
                background-size: cover;
            }
        }
        &.pic03 {
            background: url(../images/conteneurs_section05_img01.jpg) no-repeat;
            background-size: cover;
            background-position: center;
            @media screen and (max-width: $size-xs-max) {
                background: url(../images/conteneurs_section05_img01_m.jpg) no-repeat;
                background-size: cover;
            }
        }
        &.pic05 {
            background: url(../images/conteneurs_section07_img01.jpg) no-repeat;
            background-size: cover;
            background-position: center;
            @media screen and (max-width: $size-xs-max) {
                background: url(../images/conteneurs_section07_img01_m.jpg) no-repeat;
                background-size: cover;
            }
        }
    }
    @media screen and (max-width: 1750px) {
        .left-side p {
            br {
                display: none;
            }
        }
        @media screen and (max-width: 1200px) {
            flex-direction: column;
            .left-side {
                width: 100%;
                margin-right: 0;
            }
            .right-side.pic01 {
                width: 100%;
            }
            @media screen and (max-width: $size-xs-max) {
                .left-side {
                    padding: 60px 25px;
                    a.btn-yellow, a.btn-yellow.long {
                        width: 200px;
                    }
                }
            }
        }
    }
}

#section-image-bloc {
    padding: 35px 5% 0 5%;
    .left-side {
        &.pic02 {
            background: url(../images/conteneurs_section02_img01.jpg) no-repeat;
            background-size: cover;
            background-position: center;
            @media screen and (max-width: $size-xs-max) {
                background: url(../images/conteneurs_section02_img01_m.jpg) no-repeat;
                background-size: cover;
            }
        }
        &.pic04 {
            background: url(../images/conteneurs_section06_img01.jpg) no-repeat;
            background-size: cover;
            background-position: center;
            @media screen and (max-width: $size-xs-max) {
                background: url(../images/conteneurs_section06_img01_m.jpg) no-repeat;
                background-size: cover;
            }
        }
    }
    .right-side {
        width: 57%;
        background: #F2F2F2;
        margin-left: 15px;
        padding: 75px 50px;
        h3 {
            font-weight: bold;
            font-size: $font-size-30;
            text-transform: initial;
            color: $color-black;
            padding-bottom: 35px;
        }
        p {
            padding-bottom: 25px;
        }
        a.btn-yellow {
            width: 210px;
            &:hover {
                width: 215px;
            }
            &.long {
                width: 275px;
                &:hover {
                    width: 280px;
                }
            }
        }
    }
    @media screen and (max-width: 1750px) {
        .right-side p {
            br {
                display: none;
            }
        }
        @media screen and (max-width: 1200px) {
            flex-direction: column-reverse;
            .right-side {
                width: 100%;
                margin-left: 0;
            }
            @media screen and (max-width: $size-xs-max) {
                .right-side {
                    padding: 60px 25px;
                    a.btn-yellow, a.btn-yellow.long {
                        width: 200px;
                    }
                }
            }
        }
    }
}

#section-text {
    background: #A6ADB441;
    padding: 100px 50px;
    margin: 35px 5%;
    h3 {
        font-weight: bold;
        font-size: $font-size-60;
        color: $color-black;
        text-transform: initial;
        padding-bottom: 25px;
        line-height: 1;
    }
    p {
        line-height: 1.3;
    }
    h4 {
        font-weight: bold;
        font-size: $font-size-20;
        color: $color-black;
        line-height: 1.3;
        text-decoration: underline;
        text-transform: uppercase;
    }
    a.btn-yellow {
        width: 235px;
        &:hover {
            width: 240px;
        }
    }
    @media screen and (max-width: $size-md-max) {
        padding: 100px 25px;
        h3 {
            font-size: 2.5rem;
        }
        @media screen and (max-width: $size-sm-max) {
            h4 br {
                display: none;
            }

            @media screen and (max-width: $size-xs-max) {
                padding: 60px 25px;
                h3 {
                    font-size: 1.8rem;
                    br {
                        display: none;
                    }
                }
                p br {
                    display: none;
                }
                a.btn-yellow {
                    width: 175px;
                }
            }
        }
    }
}

/*******************************************************************************
* SERVICES
*******************************************************************************/

#section-image-services {
    padding: 25px 5% 75px 5%;
    .image-side {
        background: url(../images/services_section02_img01.jpg) no-repeat;
        background-size: cover;
        width: 60%;
    }
    .text-side {
        background: #A6ADB441;
        width: 50%;
        padding: 35px;
        h4 {
            font-weight: bold;
            font-size: $font-size-30;
            color: $color-black;
            padding-bottom: 25px;
        }
        a.btn-yellow {
            width: 410px;
            &:hover {
                width: 415px;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        flex-direction: column;
        .image-side, .text-side {
            width: 100%;
        }
        .text-side {
            padding: 50px 35px;
        }
        @media screen and (max-width: $size-xs-max) {
            .image-side {
                background: url("../images/services_section02_img01_m.jpg") no-repeat;
                background-size: cover;
            }
            .text-side {
                padding: 50px 15px;
                h4 br {
                    display: none;
                }
                a.btn-yellow {
                    width: 275px;
                }
            }
        }
    }
}

/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#section-coordonnees {
    padding: 50px 5% 0 5%;
    .left-side {
        width: 30%;
        h2 {
            font-weight: bold;
            font-size: $font-size-34;
            color: #171717;
        }
    }
    .right-side {
        width: 70%;
        padding-top: 5px;
        h4 {
            font-weight: bold;
            font-size: $font-size-22;
            color: $color-black;
            text-transform: uppercase;
            padding-bottom: 5px;
        }
        h5, a {
            font-weight: 500;
            font-size: $font-size-20;
            color: #909090;
        }
        .bloc {
            width: 35%;
        }
    }
    @media screen and (max-width: 1100px){
        flex-direction: column;
        .left-side {
            width: 100%;
            padding: 0px 0 50px 0;
        }
        .right-side {
            width: 85%;
            padding-top: 0;
        }
        @media screen and (max-width: $size-sm-max){
            .right-side {
                width: 100%;
                .bloc {
                    width: 50%;
                }
            }
            @media screen and (max-width: $size-xs-max){
                .right-side {
                    flex-direction: column;
                    .bloc {
                        width: 100%;
                        &:nth-child(2) {
                            padding: 30px 0;
                        }
                    }
                }
            }
        }
    }
}

#section-form-heures {
    padding: 75px 5% 0 5%;
    .section-form {
        width: 50%;
        margin-right: 10%;
        h3 {
            font-weight: bold;
            font-size: $font-size-34;
            color: #171717;
            padding-bottom: 50px;
        }
    }
    .right-side {
        width: 40%;
        h3 {
            font-weight: bold;
            font-size: $font-size-34;
            color: #21201C;
            text-transform: initial;
            padding-bottom: 10px;
        }
        .border {
            background: #B1B1B1;
            height: 1px;
            width: 525px;
            margin-bottom: 50px;
        }
        .jours-heures {
            h4, h5 {
                font-weight: bold;
                font-size: $font-size-18;
                color: $color-black;
                line-height: 2.5;
            }
            h5 {
                font-weight: normal;
            }
            .jour {
                width: 39%;
            }
        }
        .section-title {
            background: $color-1;
            width: 525px;
            padding: 25px;
            margin-top: 25px;
            h2 {
                font-weight: bold;
                font-size: $font-size-28;
                line-height: 1.3;
                color: #2F2F2F;
            }
        }
    }
    @media screen and (max-width: $size-md-max) {
        .right-side {
            .border {
                width: 450px;
            }
            .section-title {
                width: 450px;
                h2 {
                    font-size: 1.2rem;
                    text-align: center;
                }
            }
        }
        @media screen and (max-width: 1200px) {
            flex-direction: column-reverse;
            .right-side {
                width: 100%;
                margin: 0 0 75px 0;
                .jours-heures .jour {
                    width: 29%;
                }
            }
            .section-form {
                width: 100%;
                margin-right: 0;
            }
            @media screen and (max-width: $size-sm-max) {
                .right-side {
                    .jours-heures .jour {
                        width: 45%;
                    }
                }
                @media screen and (max-width: $size-xs-max) {
                    .right-side {
                        .border {
                            width: 100%;
                        }
                        .jours-heures .jour {
                            width: 60%;
                        }
                        .section-title {
                            width: 100%;
                        }
                    }
                    .section-form {
                        h3 {
                            text-align: center;
                        }
                        .col-sm-6.pr0, .col-sm-6.pl0 {
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
}

#section-map {
    padding: 100px 5% 75px 5%;
    @media screen and (max-width: $size-md-max) {
        div#map-canvas {
            height: 500px !important;
        }
    }
}

div#content {
    h3#firstHeading {
        font-size: $font-size-22;
        padding-bottom: 10px;
        color: $color-1;
    }
    p a {
        color: $color-black;
    }
}





/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: $size-md-max) {
   

}

@media screen and (max-width: $size-sm-max) {
   

}

@media screen and (max-width: $size-xs-max) {
   
    .popup-box img {
        width: 100% !important;
    }
}
