footer {
    min-height: $size-footer-height;
    @extend .bg-color-footer;
    @extend .flex;
    @extend .flex-wrap;

    .footer-wrapper {
        width: 100%;
        padding: 50px 5%;
        @extend .flex;
        @extend .flex-wrap;
        @extend .justify-between;
        @extend .text-color-grey;
        @extend .font-footer;

        .left-side {
            .text-copyright {
                margin-top: 75px;
                .copyright {
                    font-weight: 500;
                    font-size: 17px;
                    color: #DEEBEE;
                    opacity: 0.6;
                    margin-bottom: 15px;
                }
            }
        }

        .center-side {
            .bloc {
                h3 {
                    font-weight: bold;
                    font-size: $font-size-20;
                    color: $color-1;
                    text-transform: initial;
                    padding-bottom: 20px;
                }
                h4, a {
                    font-weight: 500;
                    font-size: $font-size-18;
                    color: #909090;
                    transition: 0.3s;
                }
                a {
                    border-bottom: 1px solid #909090;
                    transition: 0.3s;
                    &:hover {
                        border-bottom: none;
                        transition: 0.3s;
                    }
                }
            }
            .section-heures {
                .jour {
                    margin-right: 35px;
                }
                h4 {
                    line-height: 2;
                }
            }
        }
        @media screen and (max-width: 1600px){
            .center-side .bloc.mx16 {
                margin-left: 4rem;
                margin-right: 4rem;
            }
            @media screen and (max-width: 1400px){
                .center-side {
                    .bloc {
                        h3 {
                            font-size: 1.3rem;
                        }
                        a {
                            font-size: 0.9rem;
                        }
                        &.mx16 {
                            margin-left: 3rem;
                            margin-right: 3rem;
                        }
                    }
                }
                @media screen and (max-width: 1215px){
                    flex-direction: column-reverse;
                    align-items: initial;
                    .center-side {
                        padding: 50px 0 35px 0;
                        .bloc.mx16 {
                            margin-left: 4rem;
                            margin-right: 4rem;
                        }
                    }
                    .left-side {
                        margin-top: 0;
                        .text-copyright {
                            margin-top: 25px;
                        }
                    }
                    @media screen and (max-width: $size-xs-max){
                        .left-side .text-copyright .copyright {
                            font-size: 14px;
                        }
                        .center-side {
                            flex-direction: column;
                            padding: 50px 0;
                            .bloc {
                                h3 {
                                    padding-bottom: 10px;
                                }
                                a {
                                    border-bottom: none;
                                }
                            }
                            .bloc.mx16 {
                                margin: 25px 0;
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1215px){
        height: 510px;
        @media screen and (max-width: $size-xs-max){
            height: 650px;
        }
    }
}
