.btn {
    background-color: $color-1;
    border: none;
    border-radius: 0;
    width: 135px;
    height: 60px;

    font-weight: bold;
    font-size: $font-size-16;
    color: $color-black;
    transition: all 0.2s ease-in;

    &:hover {
        transition: all 0.2s ease-in;
        color: $color-white;
    }
}
